import { getDomainName } from "../globalApi/utils/utils";
import bestIcon from "../assets/best.svg";

const Footer = () => (
  <footer className="w-full bottom-0" style={{ backgroundColor: "#D8E2E5" }}>
    <div className="py-5 container mx-auto px-4 flex flex-col md:flex-row ">
      <img src={bestIcon} alt="bestIcon" className="mr-3 max-w-[125px]" />
      <p>© 2019-{new Date().getFullYear()} {getDomainName()}</p>
    </div>
  </footer>
);

export default Footer;
