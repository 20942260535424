import { useMemo } from "react";
import { Pair } from "../types/pair";

interface Props {
  selectedPair: Pair | null,
}

export const useGetExtraFormData = ({ selectedPair }: Props) => {
  const percent = useMemo(() => selectedPair ? selectedPair.percent : 0, [selectedPair]);

  return { 
    percent,
    min: selectedPair?.min || 1,
    max: selectedPair?.max || 100000,
  }
}
