import React, { Suspense } from 'react';
import { Sidebar } from 'flowbite-react';
import { DocumentIcon, CreditCardIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { Outlet, Link } from 'react-router-dom';
import Loader from '../components/Loader';

const Cabinet = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-fit">
        <Sidebar aria-label="Sidebar with multi-level dropdown example">
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              <Sidebar.Item icon={CreditCardIcon}>
                <Link to="/cabinet/transactions">
                  {t('cabinet.transactions')}
                </Link>
              </Sidebar.Item>
              <Sidebar.Item icon={DocumentIcon}>
                <Link to="/cabinet/documents">
                  {t('cabinet.documents')}
                </Link>
              </Sidebar.Item>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </div>
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default Cabinet;
