import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import Footer from './Footer';
import { ConfigContext } from '../globalApi/context/configContext';
import useGetRoutes from '../globalApi/hooks/useGetRoutes';

function App() {
  const content = useGetRoutes();
  const configCtx = useContext(ConfigContext);
  const { i18n } = useTranslation();

  useEffect(() => {
    configCtx?.changeConfig('onemoment');
  }, []);

  useEffect(() => {
    i18n.changeLanguage(configCtx?.config.defaultLang);
  }, [configCtx, i18n]);

  return (
    <>
      <div className="main-page-bg" />
      <div className="font-sans overflow-hidden flex flex-col	justify-between	min-h-screen">
        <div>
          <Header />
          <main className="container mx-auto px-4 mb-20 md:mt-20 relative">
            {content}
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
