import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getDomainName } from '../globalApi/utils/utils';

const Rules = () => {
  const { i18n, t } = useTranslation();

  type Rule = {
    text: string;
    subRules: Rule[] | string[];
  };

  type RulesContent = {
    content: Rule[];
  };

  const rules = useMemo(
    () =>
      (
        i18n.getDataByLanguage(i18n.resolvedLanguage)?.translation
          .rules as unknown as RulesContent
      ).content || [],
    [i18n.resolvedLanguage]
  );

  const domainName = getDomainName();

  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="blur-bg rounded-lg">
        <div className="p-8">
          <h2 className="text-center font-bold md:text-3xl text-xl mb-5">
            {t('rules.exchangeRules')}
          </h2>
          <p className="mb-5">{t('rules.description', { domainName })}</p>
          <h4>{t('rules.subDescription')}</h4>
          {rules.map((ruleFirst, indexFirst) => (
            <div key={indexFirst}>
              <h4 className="text-lg font-bold mb-3 mt-4">
                <span className="text-accent">{indexFirst + 1}. </span>
                {t(`rules.content.${indexFirst}.text`, { domainName })}
              </h4>
              {ruleFirst.subRules &&
                ruleFirst.subRules.map((ruleSecond, indexSecond) => (
                  <div key={indexSecond}>
                    <p className="ml-2 mb-2" key={indexSecond}>
                      <span className="text-accent">
                        {indexFirst + 1}.{indexSecond + 1}.{' '}
                      </span>
                      {typeof ruleSecond === 'string'
                        ? t(
                            `rules.content.${indexFirst}.subRules.${indexSecond}`,
                            { domainName }
                          )
                        : t(
                            `rules.content.${indexFirst}.subRules.${indexSecond}.text`,
                            { domainName }
                          )}
                    </p>
                    {typeof ruleSecond === 'object' &&
                      ruleSecond.subRules &&
                      ruleSecond.subRules.map((ruleThird, indexThird) =>
                        typeof ruleThird === 'string' ? (
                          <p className="ml-4 mb-2" key={indexThird}>
                            <span className="text-accent">
                              {indexFirst + 1}.{indexSecond + 1}.
                              {indexThird + 1}.{' '}
                            </span>
                            {t(
                              `rules.content.${indexFirst}.subRules.${indexSecond}.subRules.${indexThird}`,
                              { domainName }
                            )}
                          </p>
                        ) : null
                      )}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rules;
