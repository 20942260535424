import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Currency, CurrencyFrom, Pair } from "../types/pair";
import useGetSelectedPair from "./useGetSelectedPair";

interface Props {
  pairs: Pair[],
  selectedPairId: Pair['id'],
  setSelectedPairId: (id: Pair['id']) => void;
}

export const useGetSelectedCurrencies = ({ pairs, setSelectedPairId, selectedPairId }: Props) => {
  const [searchParams] = useSearchParams();
  const [fromItem, setFromItem] = useState<CurrencyFrom | null>(null);
  const [toItem, setToItem] = useState<Currency | null>(null);

  const defaultSelectedPair = useMemo(
    () => pairs.find((item) => !!item.isSelected),
    [pairs]
  );

  const selectedPair = useGetSelectedPair({selectedPairId, pairs});

  useEffect(() => {
    if (!fromItem || !toItem) return;
    const currPair = pairs.find((item) => item.from.id === fromItem.id && item.to.id === toItem.id);
    handleSelectPair(currPair?.id as Pair['id']);
  }, [fromItem, toItem, pairs])

  useEffect(() => {    
    if (!selectedPair || fromItem || toItem) return;
    setFromItem({
      ...selectedPair.from,
      max: selectedPair.max,
      min: selectedPair.min,
    })
    setToItem(selectedPair.to)
  }, [selectedPair, selectedPairId])

  useEffect(() => {
    const pairFromUrl = searchParams.get("pair");

    if (!pairs.length || selectedPair) return;

    if (pairFromUrl) {
      const pairName = pairFromUrl.replace(/-/g, '/');
      const pairFromParams = pairs.find(
        (item) => item.name === pairName
      );
      if (pairFromParams) {
        return handleSelectPair(pairFromParams.id);
      }
    } else if (defaultSelectedPair) {
      return handleSelectPair(defaultSelectedPair.id);
    } else {
      return handleSelectPair(pairs[0].id);
    }
  }, [pairs, defaultSelectedPair, searchParams]);

  const handleSelectPair = (pairId: Pair['id']) => {
    setSelectedPairId(pairId);
  }

  const handleSelectFromItem = (item: CurrencyFrom) => {
    if (!item.isAvailable) return;
    setFromItem(item);
    setToItem(null);
    handleSelectPair('');
  }

  const handleSelectToItem = (item: Currency) => {
    if (!item.isAvailable) return;
    setToItem(item);
  }

  return {
    fromItem,
    toItem,
    handleSelectPair,
    handleSelectFromItem,
    handleSelectToItem,
  }
}
