import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useGetAvailablePairs } from "../globalApi/hooks/useGetAvailablePairs";
import { PairsProps } from "../globalApi/props/interfaces";
import { CurrencyFrom } from "../globalApi/types/pair";
import { getCurrencyName } from "../globalApi/utils/formats";

const Pairs = ({ pairs, handleSelectFromItem, handleSelectToItem, toItem, fromItem }: PairsProps) => {
  const { t } = useTranslation();

  const { availableCurrenciesFrom, availableCurrenciesTo } = useGetAvailablePairs({
    pairs, fromItem,
  });

  return (
    <div className="col-span-2 grid grid-cols-2 gap-x-10">
      <div>
        <p className="font-bold text-2xl">{t("pairs.giveAway")}</p>
        <div>
          {availableCurrenciesFrom.map((currency) => (
            <button
            type="button"
              key={currency.id}
              onClick={() => handleSelectFromItem(currency as CurrencyFrom)}
              className={cn(
                "rounded-lg mt-3 flex items-center w-full hover:bg-accent hover:text-white disabled:opacity-75 transition disabled:opacity-50",
                {
                  "bg-accent text-white": fromItem?.id === currency.id,
                  "bg-white": fromItem?.id !== currency.id,
                }
              )}
            >
              <span className="p-3 font-semibold text-sm md:text-base">{getCurrencyName(currency)}</span>
            </button>
          ))}
        </div>
      </div>
      <div>
        <p className="font-bold text-2xl">{t("pairs.receive")}</p>
        <div>
          {availableCurrenciesTo.map((currency) => (
            <button
            type="button"
              key={currency.id}
              disabled={!currency.isAvailable}
              onClick={() => handleSelectToItem(currency)}
              className={cn(
                "rounded-lg mt-3 flex items-center w-full hover:bg-accent hover:text-white disabled:opacity-75 transition disabled:opacity-50",
                {
                  "bg-accent text-white": toItem?.id === currency.id,
                  "bg-white": toItem?.id !== currency.id,
                }
              )}
            >
              <span className="p-3 font-semibold text-sm md:text-base">{getCurrencyName(currency)}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pairs;
