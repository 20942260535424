import React, { memo, useContext, useEffect, useMemo } from 'react';
import Loader from '../components/Loader';
import { useGetUniquePairs } from '../globalApi/apis/pairs';
import { getResponseList } from '../globalApi/apis/utils';

import { useTranslation } from 'react-i18next';
import Pairs from '../components/Pairs';
import TransferForm from '../components/TransferForm';
import { ConfigContext } from '../globalApi/context/configContext';
import useGetSelectedPair from '../globalApi/hooks/useGetSelectedPair';
import { MainProps } from '../globalApi/props/interfaces';
import { useGetSelectedCurrencies } from '../globalApi/hooks/useGetSelectedCurrencies';

const Main = ({ clientId, selectedPairId, setSelectedPairId }: MainProps) => {
  const configCtx = useContext(ConfigContext);
  const { data, refetch, isLoading } = useGetUniquePairs(clientId);
  const pairs = useMemo(() => getResponseList(data), [data]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!clientId || !configCtx) return;
    const timeout = 1000 * 60 * configCtx.config.refetchTime;
    const interval = setInterval(refetch, timeout);
    return () => clearInterval(interval);
  }, [clientId, configCtx, refetch]);

  const selectedPair = useGetSelectedPair({ selectedPairId, pairs });

  const { handleSelectFromItem, handleSelectToItem, toItem, fromItem } = useGetSelectedCurrencies({
    pairs,
    selectedPairId,
    setSelectedPairId,
  });

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="main-bg-block rounded-lg p-10 mb-[50px]">
        <div className="md:max-w-[40%]">
          <h1 className="text-4xl	font-bold mb-6">{t('home.exchangeSafely')}</h1>
          <p className="mb-10 text-xl">{t('home.description')}</p>
          <div className="bg-white rounded-lg p-3 text-center">
            {t('home.workAroundTheClock')}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-y-10 md:gap-10">
        <Pairs {...{ pairs, handleSelectFromItem, handleSelectToItem, toItem, fromItem }} />
        <TransferForm {...{ selectedPair, toItem, fromItem }} />
      </div>
    </>
  );
};

export default memo(Main);
