import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import JSONConfig from '../config.json';

export type ConfigNames = 'other' | 'onemoment' | 'changenow' | 'excange-cryptostag' | 'exchange-cryptos' | 'master' | 'new-exchange' | 'quickchange';

export type ConfigContextType = {
  refetchTime: number;
  defaultLang: string;
};

const initialConfig: ConfigContextType = JSONConfig.other;

export type ValueContextType = {
  config: ConfigContextType;
  changeConfig: (name: ConfigNames) => void;
};

export const ConfigContext = createContext<ValueContextType | null>(null);

const ConfigContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [config, setConfig] = useState(initialConfig);

  const changeConfig = useCallback(
    (name: ConfigNames) => setConfig(JSONConfig[name] as unknown as ConfigContextType),
    [setConfig]
  );

  const value = useMemo(
    () => ({
      config,
      changeConfig,
    }),
    [config, changeConfig]
  );

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export default ConfigContextProvider;
