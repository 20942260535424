import { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useCreateClient, useGetClientByTG } from "../apis/clients";
import { useGetUniquePairs } from "../apis/pairs";
import { useCreateTransaction } from "../apis/transactions";
import { getResponseList } from "../apis/utils";
import { getCookies, setCookies } from "../utils/cookie";
import { generateRandomId, getDomainName } from "../utils/utils";
import useGetSelectedPair from "./useGetSelectedPair";
import { Pair } from "../types/pair";

interface Props {
  selectedPairId: Pair['id']
}

export const useGetAppData = ({ selectedPairId }: Props) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [clientId, setClientId] = useState('');

  const cookiesClientId = getCookies('clientId');
  const tgIdFromUrl = atob(searchParams.get('value') || '');

  const transactionId = getCookies('transactionId');

  const { status: clientByTGStatus, error: clientByTGError, data: clientByTGData } = useGetClientByTG(tgIdFromUrl);
  const createClientMutation = useCreateClient();
  const createTransactionMutation = useCreateTransaction();
  const { data, refetch } = useGetUniquePairs(clientId);

  useEffect(() => {
    if (tgIdFromUrl) {
      if (clientByTGStatus === 'error' && clientByTGError?.response?.status === 404 && !clientId) {
        createClientMutation.mutate({
          telegramId: tgIdFromUrl,
          inviteName: searchParams.get('invite_name') || undefined,
          inviteLink: searchParams.get('invite_link') || undefined,
          affiliate: searchParams.get('affiliate') || undefined,
        }, {
          onSuccess: (data) => {
            setCookies('clientId', data.data.model.id);
            setClientId(data.data.model.id)
          },
        });
      } else if (clientByTGStatus === 'success') {
        setClientId(clientByTGData.data.model.id)
      }
    } else if (cookiesClientId) {
      setClientId(cookiesClientId)
    } else {
      createClientMutation.mutate({ 
        telegramId: generateRandomId(8),
        inviteName: searchParams.get('invite_name') || undefined,
        inviteLink: searchParams.get('invite_link') || undefined,
        affiliate: searchParams.get('affiliate') || undefined,
      }, {
        onSuccess: (data) => {
          setCookies('clientId', data.data.model.id);
          setClientId(data.data.model.id)
        },
      });
    }
  }, [tgIdFromUrl, cookiesClientId, clientByTGStatus, clientByTGError, clientByTGData]);

  useEffect(() => {
    if (!clientId) return;

    refetch();
  }, [clientId]);

  const pairs = useMemo(() => getResponseList(data), [data]);

  const selectedPair = useGetSelectedPair({selectedPairId, pairs});

  useEffect(() => {
    if (transactionId || params.id) return;
    if (clientId) {
      if (!selectedPair) return;
      createTransactionMutation.mutate({
        tarnsaction: {
          status: 'visited',
          currencyFromId: selectedPair.from.id,
          currencyToId: selectedPair.to.id,
          domain: getDomainName(),
        },
        clientId,
      });
    }
  }, [clientId, transactionId, selectedPair, pairs]);

  return { clientId };
}
