import React, { useMemo } from 'react';
import { Accordion } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { getDomainName } from '../utils/utils';

const FAQ = () => {
  const { i18n, t } = useTranslation();

  const EXCHANGE_NAME = 'onemoment';

  type QuestionType = {
    question: string;
    answer: string;
    list?: string[];
    answer2?: string;
  };

  type Content = {
    faq: {
      questions: QuestionType[];
    };
  };

  const questions = useMemo(
    () =>
      (
        i18n.getDataByLanguage(i18n.resolvedLanguage)?.translation[EXCHANGE_NAME] as unknown as Content).faq.questions || [],
    [i18n.resolvedLanguage]
  );

  const domainName = getDomainName();

  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="blur-bg rounded-lg w-full">
        <div className="p-8">
          <h2 className="text-center font-bold text-3xl">
            {t('faq.questionsAnswers')}
          </h2>
          <p className="my-5">{t('faq.mostFrequentlyAskedQuestions')}</p>
          <Accordion flush>
            {questions.map((question, indexFirst) => (
              <Accordion.Panel key={indexFirst}>
                <Accordion.Title>
                  <p className="text-accent">
                    {t(`${EXCHANGE_NAME}.faq.questions.${indexFirst}.question`, {
                      domainName,
                    })}
                  </p>
                </Accordion.Title>
                <Accordion.Content>
                  {t(`${EXCHANGE_NAME}.faq.questions.${indexFirst}.answer`, {
                    domainName,
                  })}
                  {question.list && (
                    <ul className="list-disc pl-10 my-4">
                      {question.list.map((_, indexSecond) => (
                        <li key={indexSecond}>
                          {t(
                            `${EXCHANGE_NAME}.faq.questions.${indexFirst}.list.${indexSecond}`,
                            { domainName }
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                  {question.answer2 &&
                    t(`${EXCHANGE_NAME}.faq.questions.${indexFirst}.answer2`, {
                      domainName,
                    })}
                </Accordion.Content>
              </Accordion.Panel>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
