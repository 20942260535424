import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import { useUpdateTransaction } from '../globalApi/apis/transactions';
import { IMAGE_URL } from '../globalApi/apis/utils';
import { TransactionRes, UpdateTransaction } from '../globalApi/types/transaction';
import { handleCopy } from '../globalApi/utils/utils';
import Icon from './Icon';
import ErrorMessage from './ui/ErrorMessage';
import { useTranslation } from 'react-i18next';

interface Props {
  transaction: TransactionRes;
  handleConfirm: VoidFunction;
}

const ConfirmationForm = ({ transaction, handleConfirm }: Props) => {
  const { t } = useTranslation();
  const qrImage = `${IMAGE_URL}${transaction.qr}`;
  const updateTransactionMutation = useUpdateTransaction();
  const [showFullAddress, setShowFullAddress] = useState(false);

  const handleSubmit = (values: UpdateTransaction) => {
    updateTransactionMutation.mutateAsync(
      { data: values, transactionId: transaction.id },
      {
        onSuccess: handleConfirm,
      }
    );
  };

  const makeId = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  return (
    <div className="m-auto bg-white rounded-lg max-w-3xl blur-bg">
      <div className="p-8">
        <h2 className="text-center font-bold md:text-3xl text-xl mb-5">
          {t('confirmedOrder.request', { code: transaction.code })}
        </h2>
        <Formik
          initialValues={{
            hash: makeId(32),
            status: 'approved',
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="grid grid-cols-1 gap-y-10 md:grid-cols-5 md:gap-10">
              <div className="col-span-3">
                <div className="font-bold text-lg mb-6">
                  <p className="mb-4">
                    {t('pairs.transfer')} {transaction.amount}{' '}
                    {transaction.symbol}{' '}
                    {transaction.network && `(${transaction.network})`}
                  </p>
                  <p
                    className="p-2 bg-gray-900 rounded-lg flex items-center justify-between cursor-pointer text-white"
                    onClick={() => handleCopy(`${transaction.amount}`)}
                  >
                    <span className="pr-4">{transaction.amount}</span>
                    <Icon name="content_copy" />
                  </p>
                </div>
                <div className="font-bold text-lg mb-6">
                  <p className="mb-4">Переведите на указаный счет/кошелек</p>
                  <div className="flex justify-between text-xs text-secondaryText">
                    <span className="max-w-[40%]">
                      click to see in full
                    </span>
                    <span className="max-w-[40%] text-right">
                      click to copy
                    </span>
                  </div>
                  <div className="text-base p-2 bg-gray-900 rounded-lg flex items-center justify-between cursor-pointer text-white">
                    <span
                      className={cn('pr-4', {
                        truncate: !showFullAddress,
                        'text-xs': showFullAddress,
                      })}
                      onClick={() => setShowFullAddress(true)}
                    >
                      {transaction.address}
                    </span>
                    <div onClick={() => handleCopy(transaction.address)}>
                      <Icon name="content_copy" />
                    </div>
                  </div>
                </div>
                {/*<div className="font-bold text-lg mb-6">*/}
                  {/*<p className="mb-4">{t('pairs.hashTransactions')}</p>*/}
                  <Field
                    name="hash"
                    value="124124124124124124214124124124124124124124124124"
                    placeholder="Hash"
                    type="hidden"
                    className="rounded-lg bg-main w-full p-3 text-black"
                  />
                  <ErrorMessage name="hash" />
                {/*</div>*/}
                <p>{t('pairs.onceYouHaveCompleted')}</p>
              </div>
              <div className="col-span-2 bg-accent p-10 rounded-lg">
                {transaction.qr && (
                  <img
                    src={qrImage}
                    alt="qr"
                    className="p-5 bg-white rounded-lg"
                  />
                )}
                <p className="pt-4 text-xl text-center font-bold text-white">
                  {t('pairs.qrCodeForPayment')}
                </p>
              </div>
            </div>
            <button
              className="mt-10 py-3 text-lg font-bold rounded-lg bg-accent transition w-full text-white"
              type="submit"
              disabled={updateTransactionMutation.isLoading}
            >
              {t('pairs.iPaid')}
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ConfirmationForm;
