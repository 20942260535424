import { Currency, Pair } from "../types/pair";

export const formatOptions = (keys: readonly string[]) =>
  keys.map((key) => ({
    value: key,
    label: key.toUpperCase(),
  }));

export const getCurrencyName = (currency: Currency | null) =>
  !currency
    ? ""
    : currency.network
      ? `${currency.symbol} (${currency.network})`
      : currency.symbol;


export const getPairName = (pair: Pair) => (
  `${pair.from.name} ${pair.from.network ? `(${pair.from.network})` : ''} / ${pair.to.name} ${pair.to.network ? `(${pair.to.network})` : ''}`
);
