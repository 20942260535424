import React, { useState, useEffect, useMemo, memo, useRef, useContext } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { validate } from 'crypto-address-validator-ts';

import Icon from './Icon';
import ErrorMessage from './ui/ErrorMessage';
import { useUpdateTransaction } from '../globalApi/apis/transactions';
import { Transaction, TransactionForm } from '../globalApi/types/transaction';
import { getCookies } from '../globalApi/utils/cookie';
import { useTranslation } from 'react-i18next';
import { isLocal } from '../globalApi/utils/utils';
import { useGetExtraFormData } from '../globalApi/hooks/useGetExtraFormData';
import { useGetRate } from '../globalApi/hooks/useGetRate';
import { TransferFormProps } from '../globalApi/props/interfaces';
import { getCurrencyName } from '../globalApi/utils/formats';
import { SettingsContext, SettingsContextType } from '../globalApi/context/settingsContext';

const TransferForm = ({
  selectedPair,
  toItem,
  fromItem,
}: TransferFormProps) => {
  const { status } = useContext(SettingsContext) as SettingsContextType;
  const formikRef: React.Ref<FormikProps<TransactionForm>> | null =
    useRef(null);
  const { t } = useTranslation();

  const generalValidation = useMemo(
    () => ({
      email: Yup.string()
        .email(t('base.emailIsInvalid'))
        .required(t('base.required')),
      addressSending: Yup.string().required(t('base.required')),
      amountFrom: Yup.number()
        .typeError(t('base.mustBeANumber'))
        .required(t('base.required')),
      amountTo: Yup.number()
        .typeError(t('base.mustBeANumber'))
        .required(t('base.required')),
    }),
    [t]
  );

  const navigate = useNavigate();
  const [validation, setValidation] = useState(generalValidation);
  const updateTransactionMutation = useUpdateTransaction();

  const { percent, min, max } = useGetExtraFormData({
    selectedPair,
  });
  const { rate, fromItemPrice: amount, renderRate } = useGetRate({
    selectedPair,
    percent,
  });

  const emailFromCookies = useMemo(() => getCookies('email') || '', []);

  useEffect(() => {
    if (formikRef.current) {
      const { setFieldValue, values } = formikRef.current;
      const newAmount = values.amountFrom * Number(rate);
      setFieldValue('amountTo', newAmount);
    }
  }, [rate]);

  useEffect(() => {
    setValidation((prevState) => ({
      ...prevState,
      amountFrom: Yup.number()
        .max(max)
        .min(min)
        .typeError('Must be a number')
        .required(t('base.required')),
      addressSending: Yup.string()
        .test('crypto-validate', 'Wallet is not valid', (value) => {
          if (isLocal()) return true;
          if (toItem?.network === 'TRC20' && value) {
            return /T[A-Za-z1-9]{33}/.test(value);
          }

          // return validate(value as string, toItem?.symbol || '', {
          //   networkType: toItem?.network || toItem?.symbol || '',
          //   chainType: '',
          // });
          return true;
        })
        .required(t('base.required')),
    }));
    formikRef.current?.validateForm();
  }, [selectedPair, min, max]);

  const initialValues: TransactionForm = {
    email: emailFromCookies,
    addressSending: '',
    amountFrom: min || 1,
    amountTo: 0,
  };

  const handleSubmit = async (values: TransactionForm) => {
    if (!status) return;
    const data: Transaction = {
      ...values,
      currencyFromId: fromItem?.id || '',
      currencyToId: toItem?.id || '',
      status: 'created',
      amount: amount * Number(values.amountFrom),
      percent,
    };
    updateTransactionMutation.mutateAsync(
      { data },
      {
        onSuccess: (data) => navigate(`/qr/${data.data.model.id}`),
      }
    );
  };

  return (
    <div className="col-span-3">
      <div>
        <div className="flex items-baseline flex-col">
          <p className="font-bold text-lg md:text-2xl">
            <Icon className="mr-3 text-base md:text-lg" name="sync" />
            <span>{t('pairs.exchange')}</span>
          </p>
          {toItem && fromItem && <p className="ml-5 text-base md:text-lg text-secondaryText">
            {t('pairs.exchangeRate')}:{' '}
            <span className="block md:inline">{renderRate}</span>
          </p>}
          <p className="ml-5 text-base md:text-lg text-secondaryText">
            {toItem && `${t('pairs.available')}: ${toItem.available}`}
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape(validation)}
          enableReinitialize
          innerRef={formikRef}
        >
          {({ setFieldValue }) => {
            return (
              <Form className="bg-white w-full rounded-lg mt-3">
                <div className="p-8">
                  <div className="grid-cols-2 grid">
                    <div className="flex flex-col justify-between">
                      <p>
                        {t('pairs.weChange')} {getCurrencyName(fromItem)}
                      </p>
                      {min && max && (
                        <span className="text-sm text-secondaryText">
                          ({t('base.min')} - {min}, {t('base.max')} -{' '}
                          {max})
                        </span>
                      )}
                      <div>
                        <div>
                          <Field
                            disabled={!status}
                            name="amountFrom"
                            placeholder={min}
                            className="mt-3 rounded-lg bg-main w-full p-3 text-black"
                            type="number"
                            onChange={(
                              e: React.ChangeEvent<{ value: string }>
                            ) => {
                              setFieldValue('amountFrom', e.target.value);
                              setFieldValue(
                                'amountTo',
                                Number(e.target.value) * Number(rate)
                              );
                            }}
                          />
                          <ErrorMessage name="amountFrom" />
                        </div>
                        <div>
                          <Field
                            disabled={!!emailFromCookies || !status}
                            name="email"
                            placeholder="E-mail"
                            className="mt-3 rounded-lg bg-main w-full p-3 text-black"
                          />
                          <ErrorMessage name="email" />
                        </div>
                      </div>
                    </div>
                    <div className="ml-5 flex flex-col justify-between">
                      <p>
                        {t('base.to')} {getCurrencyName(toItem)}
                      </p>
                      <div>
                        <div>
                          <Field
                            name="amountTo"
                            placeholder="400"
                            className="mt-3 rounded-lg bg-main w-full p-3 text-black"
                            type="number"
                            disabled
                          />
                          <ErrorMessage name="amountTo" />
                        </div>
                        <div>
                          <Field
                            disabled={!status}
                            name="addressSending"
                            placeholder={`${getCurrencyName(toItem)} ${t(
                              'pairs.wallet'
                            )}`}
                            className="mt-3 rounded-lg bg-main w-full p-3 text-black"
                          />
                          <ErrorMessage name="addressSending" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-secondaryText text-center text-sm mt-4">
                    {t('pairs.pushingTheButton')}{' '}
                    <Link className="underline" to="/rules">
                      {t('headerLinks.rules')}
                    </Link>
                    .
                  </p>
                  <button
                    disabled={!status}
                    type="submit"
                    className="rounded-lg mt-4 bg-black hover:bg-accent text-white w-full py-3 transition"
                  >
                    {t('pairs.proceedToCheckout')}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default memo(TransferForm);
