import { useMemo } from 'react';
import { Pair } from "../types/pair";

interface Props {
  selectedPairId: Pair['id'],
  pairs: Pair[],
}

const useGetSelectedPair = ({ selectedPairId, pairs }: Props) => {
  return useMemo(() => {
    return pairs.find(pair => pair.id === selectedPairId) || null
  }, [pairs, selectedPairId])
};

export default useGetSelectedPair;
