import { useMemo } from "react";
import { CurrencyFrom, Pair } from "../types/pair";
import { useGetUniquePairs } from "./useGetUniquePairs";

interface Props {
  pairs: Pair[],
  fromItem: CurrencyFrom | null,
}

export const useGetAvailablePairs = ({ pairs, fromItem }: Props) => {
  const { uniqueCurrenciesFrom, uniqueCurrenciesTo } = useGetUniquePairs({ pairs });

  const availableToItemsIds = useMemo(() => {
    if (!fromItem) return [];
    return pairs.filter(pair => pair.from.id === fromItem.id).map(pair => pair.to.id);
  }, [fromItem, pairs]);

  const availableCurrenciesFrom = useMemo(() => (
    fromItem ? uniqueCurrenciesFrom.map(currency => ({
      ...currency,
      isAvailable: true,
    })) : []
  ), [uniqueCurrenciesFrom, fromItem]);

  const availableCurrenciesTo = useMemo(() => uniqueCurrenciesTo.map(currency => ({
    ...currency,
    isAvailable: availableToItemsIds.includes(currency.id),
  })), [uniqueCurrenciesTo, availableToItemsIds]);

  return { availableCurrenciesFrom, availableCurrenciesTo }
}
