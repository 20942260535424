import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useGetTransaction } from '../globalApi/apis/transactions';
import { getResponseModel } from '../globalApi/apis/utils';
import ConfirmationForm from '../components/ConfirmationForm';
import Loader from '../components/Loader';

const QrForm = () => {
  const params = useParams();
  const { data, refetch, isRefetching } = useGetTransaction(params.id);
  const transaction = getResponseModel(data);
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  const handleConfirm = () => {
    toast.success('Approved!');
    navigate(`/qr/${params.id}/confirmed`);
  };

  if (isRefetching) return <Loader />;

  if (!transaction) return null;

  return (
    <ConfirmationForm handleConfirm={handleConfirm} transaction={transaction} />
  );
};

export default QrForm;
