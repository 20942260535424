import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { Client, CreateClient } from "../types/client";
import { setCookies } from "../utils/cookie";
import { instance } from "./utils";

const BASE_URL = 'clients';
const CLIENT = 'CLIENT';
const CREATED_CLIENT = 'CREATED_CLIENT';

export const useGetClientByTG = (tgId: string) => useQuery<AxiosResponse<{ model: Client }>, AxiosError>(
  [CLIENT],
  () => instance.get(`${BASE_URL}/tg/${tgId}`),
  {
    enabled: !!tgId,
    onSuccess: (data) => setCookies('clientId', data.data.model.id),
    onError: () => { },
  }
)

export const useCreateClient = () => useMutation<AxiosResponse<{ model: Client }>, unknown, CreateClient>((data) => {
  const sendingData = { ...data, notificationStatus: 1 }
  if (!sendingData.inviteName) delete sendingData.inviteName;
  if (!sendingData.inviteLink) delete sendingData.inviteLink;
  if (!sendingData.affiliate) delete sendingData.affiliate;

  return instance.post(BASE_URL, sendingData)
}, {
  mutationKey: [CREATED_CLIENT],
});
