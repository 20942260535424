import { useContext, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Documents from '../../components/Documents';
import PrivateLayout from '../../components/PrivateLayout';
import Transactions from '../../components/Transactions';
import Cabinet from '../../pages/Cabinet';
import ConfirmedOrder from '../../pages/ConfirmedOrder';
import Contacts from '../../pages/Contacts';
import Login from '../../pages/Login';
import Main from '../../pages/Main';
import QrForm from '../../pages/QrForm';
import Rules from '../../pages/Rules';
import { useGetUniquePairs } from '../apis/pairs';
import {
  SettingsContext,
  SettingsContextType,
} from '../context/settingsContext';
import FAQ from '../pages/FAQ';
import TechnicalProblem from '../pages/TechnicalProblems';
import UpdatingWalletNodes from '../pages/UpdatingWalletNodes';
import { useGetAppData } from './useGetAppData';
import { Pair } from '../types/pair';

const useGetRoutes = () => {
  const [selectedPairId, setSelectedPairId] = useState<Pair['id']>('');
  const { clientId } = useGetAppData({ selectedPairId });
  const { isError } = useGetUniquePairs(clientId);
  const { status } = useContext(SettingsContext) as SettingsContextType;

  if (isError) return <TechnicalProblem />;
  if (!status) return <UpdatingWalletNodes />;
  return (
    <Routes>
      <Route
        index
        element={
          <Main
            clientId={clientId}
            selectedPairId={selectedPairId}
            setSelectedPairId={setSelectedPairId}
          />
        }
      />
      <Route path="qr/:id" element={<QrForm />} />
      <Route path="qr/:id/confirmed" element={<ConfirmedOrder />} />
      <Route path="/login" element={<Login />} />
      <Route path="/rules" element={<Rules />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="*" element={<Navigate to="/" replace />} />

      <Route
        path="/cabinet"
        element={
          <PrivateLayout>
            <Cabinet />
          </PrivateLayout>
        }
      >
        <Route path="/cabinet/transactions" element={<Transactions />} />
        <Route path="/cabinet/documents" element={<Documents />} />
      </Route>
    </Routes>
  );
};

export default useGetRoutes;
