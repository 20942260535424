import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { SettingsContextType } from '../context/settingsContext';
import { instance } from './utils';
import { getDomainName } from '../utils/utils';
import { ModelRes } from '../types/global';

const BASE_URL = 'sites';
const SETTINGS = 'SETTINGS';

export const useGetSettings = () => useQuery<unknown, unknown, AxiosResponse<ModelRes<SettingsContextType>>>(
  [SETTINGS],
  () => instance.get(`${BASE_URL}/${getDomainName()}`),
);
