import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useGetSettings } from '../apis/settings';
import { getResponseModel, IMAGE_URL } from '../apis/utils';
import Loader from '../../components/Loader';

export type SettingsContextType = {
  createdAt?: string;
  domain?: string;
  id?: string;
  logo: string;
  name: string;
  support: string;
  status: boolean,
};

const initialSettings = {
  name: '',
  logo: '',
  support: '',
  status: true,
};

export const SettingsContext = createContext<SettingsContextType | null>(null);

const SettingsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data, isSuccess, isLoading } = useGetSettings();
  const [settings, setSettings] = useState(initialSettings);
  const settingsData = useMemo(() => {
    if (isSuccess) {
      const res = getResponseModel(data);
      return {
        ...res,
        logo: `${IMAGE_URL}${res.logo}`,
        status: !!res.status,
      };
    } else {
      return initialSettings;
    }
  }, [isSuccess, data]);

  useEffect(() => {
    setSettings(settingsData);
  }, [settingsData]);

  if (isLoading) {
    <Loader />
  }

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
