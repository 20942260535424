
import { useEffect, useMemo, useState } from "react";
import useWebSocket from "react-use-websocket";

import { Currency, Pair } from "../types/pair";
import { getPairName } from "../utils/formats";

const BASE_URL = "wss://stream.binance.com:9443/ws";

interface Props {
  selectedPair: Pair | null,
  percent: number,
}

export const useGetRate = ({ selectedPair, percent }: Props) => {
  const [rate, setRate] = useState("");
  const [renderRate, setRenderRate] = useState("");
  const [socketUrlFrom, setSocketUrlFrom] = useState("");
  const [socketUrlTo, setSocketUrlTo] = useState("");
  const { lastJsonMessage: lastMessageFrom } =
    useWebSocket<{ p: string } | null>(socketUrlFrom, {}, !!socketUrlFrom);
  const { lastJsonMessage: lastMessageTo } =
    useWebSocket<{ p: string } | null>(socketUrlTo, {}, !!socketUrlFrom);

  useEffect(() => {
    if (!selectedPair) return;
    
    const toPrice = Number(lastMessageTo ? lastMessageTo.p : selectedPair.to.price);
    const fromPrice = Number(lastMessageFrom ? lastMessageFrom.p : selectedPair.from.price);
    const localRate = fromPrice / toPrice;
    const rateWithPercent = (percent
      ? localRate * (1 + percent / 100)
      : localRate).toFixed(5);

    const [fromItem, toItem] = getPairName(selectedPair).split('/')

    if (fromPrice > toPrice) {
      setRenderRate(`1 ${fromItem} = ${rateWithPercent} ${toItem}`)
    } else {
      setRenderRate(`${rateWithPercent} ${fromItem} = 1 ${toItem}`)
    }

    setRate(rateWithPercent);
  }, [lastMessageFrom, lastMessageTo, selectedPair]);

  const fromItemPrice = useMemo(
    () => selectedPair ? Number(lastMessageFrom ? lastMessageFrom.p : selectedPair.from.price) : 0,
    [lastMessageFrom, selectedPair]
  )

  useEffect(() => {
    if (!selectedPair) return;
    handleOpenSocket(selectedPair.from, selectedPair.to);
  }, [selectedPair]);

  const handleOpenSocket = (from: Currency, to: Currency) => {
    setSocketUrlFrom(`${BASE_URL}/${from.symbol.toLowerCase()}usdt@trade`);
    setSocketUrlTo(`${BASE_URL}/${to.symbol.toLowerCase()}usdt@trade`);
  };

  return { rate, fromItemPrice, renderRate }
}
